@import '../../styles/index.scss';

.Nutriscore {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.Nutriscore__icon {
  height: auto;
  width: 100px;
}

@include generateClassesForOrganizations(
  'Nutriscore__icon',
  'margin-right',
  margin-label
);
@include generateClassesForOrganizations(
  'Nutriscore__icon',
  'margin-bottom',
  margin-label
);
