@import '../../styles/index.scss';

.BrandPicture {
  max-height: 150px;
  max-width: 100%;
  object-fit: contain;
}

@include generateClassesForOrganizations(
  'BrandPicture',
  'margin-right',
  margin-base
);
