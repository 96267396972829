@import '../../styles/index.scss';

.Labels {
  display: flex;
  flex-direction: column;
}

.Labels__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Label {
  background-position: center center;
  background-size: cover;
  border-radius: 5px;
}

@include generateClassesForOrganizations('Label', 'height', label-height);
@include generateClassesForOrganizations('Label', 'margin-right', margin-label);
@include generateClassesForOrganizations(
  'Label',
  'margin-bottom',
  margin-label
);
@include generateClassesForOrganizations('Label', 'width', label-height);
