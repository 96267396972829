@import './styles/index.scss';

:root {
  --primary-color: map-get($vars, primary-color);
  --secondary-color: map-get($vars, secondary-color);
  --background-color: map-get($vars, background-color);
  --background-color-header: map-get($vars, background-color);
  --border-color: map-get($vars, border-color);
  --font-family: map-get($vars, font-family);
  --font-size: map-get($vars, font-size);

  --header-border-bottom-color: var(--header-color);
  --header-border-bottom-style: solid;
  --header-border-bottom-width: 0;
  --header-border-top-width: 3px;
  --header-color: var(--primary-color);
  --header-font-size: 18px;
  --header-font-weight: 700;
  --header-padding-bottom: 0;

  --allergens-font-size: var(--font-size);
  --contact-font-size: var(--font-size);

  --tabs-border-top-width: 3px;

  --margin-base: 20px;
  --half-margin-base: calc(var(--margin-base) / 2);
  --label-height: 32px;
  --margin-label: 6px;
  --padding-base: 12px;
  --half-padding-base: calc(var(--padding-base) / 2);
  --font-color-negative: white;
}

/* Cannot use variable here */
@font-face {
  font-family: 'TitilliumText22LRegular';
  src: url('./assets/fonts/TitilliumText22L002.otf');
  font-weight: normal;
  font-style: normal;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 100%;
}

@each $organization in $organizations {
  body.#{$organization} {
    background: map-deep-get($vars, $organization, background-color);
    background: var(
      --background-color,
      map-deep-get($vars, $organization, background-color)
    );

    font-family: map-deep-get($vars, $organization, font-family);
    font-family: var(
      --font-family,
      map-deep-get($vars, $organization, font-family)
    );

    font-size: map-deep-get($vars, $organization, font-size);
    font-size: var(--font-size, map-deep-get($vars, $organization, font-size));
  }
}
