@import '../../styles/index.scss';

.Manufacturer__label {
  background-color: #b8b8b8;
  border-radius: 0.25em;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  line-height: 1;
  padding: 0.3em 0.6em 0.2em;
  text-decoration: none;
}

@include generateSelectorForOrganizations(
  '.Manufacturer__address',
  'margin-bottom',
  half-margin-base
);

@include generateSelectorForOrganizations(
  '.Manufacturer__label + .Manufacturer__label',
  'margin-left',
  margin-label
);

@include generateClassesForOrganizations(
  'Manufacturer__additionalInformation',
  'font-size',
  contact-font-size
);
