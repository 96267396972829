@import '../../styles/index.scss';

@include generateClassesForOrganizations(
  'Allergens',
  'font-size',
  allergens-font-size
);
@include generateClassesForOrganizations(
  'Allergens',
  'padding-bottom',
  half-padding-base
);

.Allergens__contains {
  color: #d9534f;
}

.Allergens__mayContain {
  color: #f0ad4e;
}

.Allergens__freeFrom {
  color: #5cb85c;
}
