@import '../../styles/index.scss';

.LegalInformation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
  text-align: center;
}

@include generateClassesForOrganizations(
  'LegalInformation',
  'margin-left',
  margin-base
);
@include generateClassesForOrganizations(
  'LegalInformation',
  'margin-right',
  margin-base
);
@include generateClassesForOrganizations(
  'LegalInformation',
  'padding-bottom',
  padding-base
);
@include generateClassesForOrganizations(
  'LegalInformation',
  'background-color',
  background-color
);

.LegalInformation__separator {
  border: 0;
  height: 1px;
  background: #333;
}
