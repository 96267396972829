@import '../../styles/index.scss';

@include generateClassesForOrganizations('Tabs', 'margin', margin-base);
@include generateClassesForOrganizations(
  'Tabs',
  'margin-bottom',
  margin-bottom-tabs
);

.Tabs__wrapper {
  background-color: white;
  border-bottom: 1px solid #e5e5e5;
  border-top-style: solid;
  display: flex;
  flex-direction: row;
}

@include generateClassesForOrganizations(
  'Tabs__wrapper',
  'border-top-width',
  tabs-border-top-width
);
@include generateClassesForOrganizations(
  'Tabs__wrapper',
  'border-top-color',
  secondary-color
);
@include generateClassesForOrganizations(
  'Tabs__wrapper',
  'margin-bottom',
  margin-base
);

.Tabs__tabWrapper {
  cursor: pointer;
  flex: 2;
  padding: 12px;
  text-align: center;
}

.Tabs__navigationArrow,
.Tabs__tabWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Tabs__tabWrapper + .Tabs__tabWrapper {
  border-left: 1px solid var(--background-color);
}

.Tabs__tabWrapper.active {
  position: relative;
}
@include generateClassesForOrganizations(
  'Tabs__tabWrapper.active',
  'background',
  secondary-color
);

.Tabs__tabWrapper.active:after {
  content: ' ';
  position: absolute;
  bottom: -8px;
  left: 50%;
  margin-left: -8px;
  height: 0;
  width: 0;
  border: 8px solid transparent;
  border-bottom: 0;
}
@include generateClassesForOrganizations(
  'Tabs__tabWrapper.active:after',
  'border-top-color',
  secondary-color
);

.Tabs__tabWrapper:hover,
.Tabs__tabWrapper:active,
.Tabs__navigationArrow--active:hover,
.Tabs__navigationArrow--active:active {
  transition: background-color 0.3s ease;
}

@each $classname
  in (
    'Tabs__tabWrapper:hover',
    'Tabs__tabWrapper:active',
    'Tabs__navigationArrow--active:hover',
    'Tabs__navigationArrow--active:active'
  )
{
  @include generateClassesForOrganizations(
    $classname,
    'background-color',
    secondary-color
  );
  @include generateClassesForOrganizations(
    $classname,
    'color',
    font-color-negative
  );
}

.Tabs__navigationArrow {
  flex: 1;
  font-size: 28px;
}

.Tabs__navigationArrow--active {
  cursor: pointer;
}

@include generateClassesForOrganizations(
  'Tabs__navigationArrow--active',
  'color',
  secondary-color
);

.Tabs__navigationArrow--disable {
  color: gray;
}
