@import '../../styles/index.scss';

@include generateClassesForOrganizations(
  'ProductPictures',
  'margin-bottom',
  margin-base
);
@include generateClassesForOrganizations(
  'ProductPictures',
  'margin-right',
  margin-base
);

.ProductPicture {
  height: 300px;
}

.slide {
  background: transparent;
}

@include generateClassesForOrganizations('slide', 'margin-bottom', margin-base);

.carousel .control-dots .dot {
  box-shadow: none;
  border: 2px solid #b8b8b8;
  height: 10px;
  width: 10px;
}

.carousel .control-dots .dot.selected {
  background-color: #b8b8b8;
}

.carousel .slider-wrapper,
.carousel.carousel-slider {
  overflow: visible !important;
}

.carousel .slide {
  opacity: 0;
  transition: opacity 0s ease-in-out;
}

.carousel .slide.selected {
  opacity: 1;
  transition: opacity 0.35s ease-in-out;
}

.carousel .slide * {
  cursor: default !important;
}

.carousel .slide.selected * {
  cursor: crosshair !important;
}

@include generateClassesForOrganizations(
  'ProductPicture--zoomContainer',
  'background-color',
  background-color-header
);
