@import '../../styles/index.scss';

.Header {
  border-top-style: solid;
  display: flex;
  overflow: hidden;
}

@include generateClassesForOrganizations(
  'Header',
  'background',
  background-color-header
);
@include generateClassesForOrganizations(
  'Header',
  'border-top-width',
  header-border-top-width
);
@include generateClassesForOrganizations(
  'Header',
  'border-top-color',
  header-color
);
@include generateClassesForOrganizations('Header', 'margin', margin-base);
@include generateClassesForOrganizations('Header', 'padding', margin-base);

@media all and (max-width: 667px) {
  .Header {
    flex-direction: column;
  }
}

.Header__column {
  flex: 1;
  flex-direction: row;
}

.Header__column--left {
  flex: 5;
}

.Header__column--right {
  flex: 7;
}

.Header__secondaryPictureAndLabels {
  display: flex;
  flex-direction: row;
}

.BrandPicture {
  flex: 1;
}

.Labels {
  flex: 3;
}
