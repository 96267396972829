@import '../../../styles/index.scss';

.NutritionTable {
  border-collapse: collapse;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}
@include generateClassesForOrganizations(
  'NutritionTable',
  'margin-bottom',
  margin-base
);

@include generateClassesForOrganizations(
  'NutritionTable__header',
  'background-color',
  secondary-color
);
@include generateClassesForOrganizations(
  'NutritionTable__header',
  'color',
  font-color-negative
);

.NutritionTable__headerCell {
  border: none;
  text-align: left;
}

@include generateClassesForOrganizations(
  'NutritionTable__headerCell',
  'padding',
  padding-base
);

@include generateClassesForOrganizations(
  'NutritionTable__cell',
  'padding-top',
  half-padding-base
);
@include generateClassesForOrganizations(
  'NutritionTable__cell',
  'padding-bottom',
  half-padding-base
);
@include generateClassesForOrganizations(
  'NutritionTable__cell',
  'padding-left',
  padding-base
);
@include generateClassesForOrganizations(
  'NutritionTable__cell',
  'padding-rigth',
  padding-base
);
