$organizations: ('default', 'auchandrive', 'casinodrive');

$vars: (
  nth($organizations, 1): (
    primary-color: #de0b1e,
    secondary-color: #3f9048,
    background-color: #f7f7f7,
    background-color-header: white,
    border-color: #b8b8b8,
    font-family: 'TitilliumText22LRegular',
    font-size: 14px,
    viewport-height: 800px,
    header-border-bottom-color: #de0b1e,
    header-border-bottom-style: solid,
    header-border-bottom-width: 0,
    header-border-top-width: '3px',
    header-color: #de0b1e,
    header-font-size: '18px',
    header-font-weight: 700,
    header-padding-bottom: 0,
    allergens-font-size: 14px,
    contact-font-size: 14px,
    tab-header-text-transform: default,
    tabs-border-top-width: 3px,
    margin-base: 20px,
    half-margin-base: 10px,
    label-height: 32px,
    margin-label: 6px,
    margin-bottom-tabs: 150px,
    padding-base: 12px,
    half-padding-base: 6px,
    font-color-negative: white,
  ),
  nth($organizations, 2): (
    primary-color: #de0b1e,
    secondary-color: #3f9048,
    background-color: #f7f7f7,
    background-color-header: white,
    border-color: #b8b8b8,
    font-family: 'TitilliumText22LRegular',
    font-size: 14px,
    viewport-height: 800px,
    header-border-bottom-color: #de0b1e,
    header-border-bottom-style: solid,
    header-border-bottom-width: 0,
    header-border-top-width: '3px',
    header-color: #de0b1e,
    header-font-size: '18px',
    header-font-weight: 700,
    header-padding-bottom: 0,
    allergens-font-size: 14px,
    contact-font-size: 14px,
    tab-header-text-transform: default,
    tabs-border-top-width: 3px,
    margin-base: 20px,
    half-margin-base: 10px,
    label-height: 32px,
    margin-label: 6px,
    margin-bottom-tabs: 150px,
    padding-base: 12px,
    half-padding-base: 6px,
    font-color-negative: white,
  ),
  nth($organizations, 3): (
    primary-color: #352a26,
    secondary-color: #b8b8b8,
    background-color: #ffffff,
    background-color-header: white,
    border-color: #b8b8b8,
    font-family: 'Helvetica,Arial,sans-serif',
    font-size: 11px,
    viewport-height: 100%,
    header-border-bottom-width: 1px,
    header-border-bottom-style: solid,
    header-border-bottom-color: #352a26,
    header-border-top-width: 0,
    header-text-transform: uppercase,
    header-color: #352a26,
    header-font-size: 14px,
    header-font-weight: 400,
    header-padding-bottom: 5px,
    allergens-font-size: 14px,
    contact-font-size: 14px,
    tab-header-text-transform: uppercase,
    tabs-border-top-width: 1px,
    margin-base: 20px,
    half-margin-base: 10px,
    label-height: 32px,
    margin-label: 6px,
    margin-bottom-tabs: 150px,
    padding-base: 12px,
    half-padding-base: 6px,
    font-color-negative: white,
  ),
);

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

@mixin generateSelectorForOrganizations($selector, $property, $varName) {
  @each $organization in $organizations {
    .#{$organization} #{$selector} {
      #{$property}: map-deep-get($vars, $organization, $varName);
      #{$property}: var(
        --#{$varName},
        map-deep-get($vars, $organization, $varName)
      );
    }
  }
}

@mixin generateClassesForOrganizations($classname, $property, $varName) {
  @include generateSelectorForOrganizations(
    ('.' + $classname),
    $property,
    $varName
  );
}
