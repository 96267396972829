@import '../../styles/index.scss';

.TabIcon {
  height: 44px;
  margin-bottom: 5px;
}

.Tab--active,
.Tabs__tabWrapper:hover .Tab,
.Tabs__tabWrapper:active .Tab {
  color: #fff;
  fill: #fff;
}

.Tab__label {
  text-align: center;
  width: 100%;
}

@include generateClassesForOrganizations(
  'Tab__label',
  'text-transform',
  tab-header-text-transform
);
