@import '../../styles/index.scss';

.Field {
  position: relative;
}

.FieldText--withIcon {
  position: absolute;
  bottom: 5px;
  left: 40px;
  float: left;
}

.Field__Icon svg {
  width: 30px;
}

@include generateClassesForOrganizations(
  'FieldText--pictureIcon',
  'height',
  label-height
);
@include generateClassesForOrganizations(
  'FieldText--pictureIcon',
  'margin-right',
  margin-label
);
@include generateClassesForOrganizations(
  'FieldText--pictureIcon',
  'width',
  label-height
);
